<template>
  <div class="main index" v-if="!isLoading" ref="element">
    <div class="columns">
      <div class="column is-9">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li>
              <a href>
                <h3 class="is-size-3">INTERVIEW SCHEDULE</h3>
              </a>
            </li>
          </ul>
        </nav>
      </div>

      <div class="column is-2">
        <router-link :to="{ name: `logInterviewSchedule` }">
          <b-field grouped position="is-right">
            <b-button type="is-hcc">History</b-button>
          </b-field>
        </router-link>
      </div>
      <div class="column is-1">
        <router-link :to="{ name: `createInterviewSchedule` }">
          <b-field grouped position="is-right">
            <b-button type="is-hcc">Create</b-button>
          </b-field>
        </router-link>
      </div>
    </div>
    <div class="box">
      <div class="columns">
        <span class="column is-4">
          <b-field>
            <b-input
              v-model="search"
              placeholder="Search..."
              type="search"
              icon="search"
            ></b-input>
          </b-field>
        </span>
        <span class="column is-8">
          <b-field grouped position="is-right">
            <b-select v-model="perPage" @input="onPageChange(currentPage)">
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
            </b-select>
          </b-field>
        </span>
      </div>
      <div class="columns">
        <div class="column is-12">
          <!-- <div class="box"> -->
          <b-table
            :data="
              getInterviewSchedules && getInterviewSchedules.data
                ? getInterviewSchedules.data
                : []
            "
            :current-page.sync="currentPage"
            paginated
            backend-pagination
            :total="
              getInterviewSchedules && getInterviewSchedules.total
                ? getInterviewSchedules.total
                : 0
            "
            :per-page="perPage"
            @page-change="onPageChange"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            backend-sorting
            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"
            @sort="onSort"
            :loading="isTableLoading"
          >
            <template slot-scope="props">
              <b-table-column field="index" numeric width="20" label="No">
                <template slot="header" slot-scope="{ column }">
                  <span :label="column.label" class="table-header">{{
                    column.label
                  }}</span>
                </template>
                {{ props.index + getInterviewSchedules.meta.from }}
              </b-table-column>
              <b-table-column field="page_title" label="Case Study" sortable>
                <template slot="header" slot-scope="{ column }">
                  <span :label="column.label" class="table-header">{{
                    column.label
                  }}</span>
                </template>
                {{ props.row.caseStudy }}
              </b-table-column>
              <b-table-column
                field="interview_date"
                label="Interview Date"
                sortable
              >
                <template slot="header" slot-scope="{ column }">
                  <span :label="column.label" class="table-header">{{
                    column.label
                  }}</span>
                </template>
                {{ props.row.interviewDate }}
              </b-table-column>
              <b-table-column field="created_at" label="Created At" sortable>
                <template slot="header" slot-scope="{ column }">
                  <span :label="column.label" class="table-header">{{
                    column.label
                  }}</span>
                </template>
                {{ props.row.createdAt }}
              </b-table-column>
              <b-table-column field="action" label sortable centered>
                <template slot="header" slot-scope="{ column }">
                  <span :label="column.label" class="table-header">
                    {{ column.label }}
                  </span>
                </template>
                <b-button class="button-action" @click="edit(props.row.id)"
                  >Edit</b-button
                >
                <b-button
                  class="button-action"
                  @click="resendPopup(props.row.id)"
                  >Resend</b-button
                >
                <b-button
                  class="button-action"
                  @click="deletePopup(props.row.id, props.index)"
                  >Delete</b-button
                >
              </b-table-column>
            </template>
            <template slot="bottom-left">
              {{
                getInterviewSchedules && getInterviewSchedules.meta.from
                  ? getInterviewSchedules.meta.from
                  : 0
              }}
              -
              {{
                getInterviewSchedules && getInterviewSchedules.meta.to
                  ? getInterviewSchedules.meta.to
                  : 0
              }}
              from
              {{
                getInterviewSchedules && getInterviewSchedules.meta.total
                  ? getInterviewSchedules.meta.total
                  : 0
              }}
            </template>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon icon="frown" size="is-large"></b-icon>
                  </p>
                  <p>Data not found.</p>
                </div>
              </section>
            </template>
          </b-table>
          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      isLoading: false,
      isTableLoading: true,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: "bottom",
      defaultSortDirection: "asc",
      defaultSortOrder: "desc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      currentPage: 1,
      perPage: 10,
      activeTab: 0,
      sortField: "created_at",
      sortOrder: "asc",
      page: 1,
      search: null
    };
  },
  mounted() {
    this.fetchData(this.perPage, this.currentPage);
  },
  watch: {
    search: function(val) {
      this.searchInterview(val);
    }
  },
  methods: {
    edit(id) {
      this.$router.push({ path: `/interview-schedule/edit/${id}` });
    },
    resend(id) {
      const loadingComponent = this.$buefy.loading.open({
        container: this.isFullPage ? null : this.$refs.element.$el
      });
      this.$store.dispatch("resendEmail", id).then(response => {
        if (response == 400) {
          this.danger("Gagal mengirim ulang email");
        } else {
          this.success("berhasil mengirim email");
        }
        loadingComponent.close();
      });
    },
    deletePopup(id, index) {
      this.$buefy.dialog.confirm({
        title: "Delete Interview Schedule",
        message: `Are you sure want to delete this interview schedule?`,
        cancelText: "No, cancel it!",
        confirmText: "Yes, delete it!",
        type: "is-danger",
        onConfirm: () => this.deleteInterviewSchedule(id, index)
      });
    },
    resendPopup(id) {
      this.$buefy.dialog.confirm({
        title: "Resend Interview Schedule",
        message: `Are you sure want to resend interview schedule?`,
        cancelText: "No",
        confirmText: "Yes",
        type: "is-hcc",
        onConfirm: () => this.resend(id)
      });
    },
    searchInterview(value) {
      if (value) {
        this.search = value;
        this.fetchData(
          this.perPage,
          this.page,
          this.sortField,
          this.sortOrder,
          this.search
        );
      } else {
        this.search = null;
        this.fetchData(
          this.perPage,
          this.page,
          this.sortField,
          this.sortOrder,
          this.search
        );
      }
    },
    async fetchData(perPage, page, sortField, sortOrder, search) {
      this.isTableLoading = true;
      await this.$store.dispatch("getInterviewSchedules", {
        perPage,
        page,
        sortField,
        sortOrder,
        search
      });
      this.isTableLoading = false;
    },
    async deleteInterviewSchedule(id, index) {
      const loadingComponent = this.$buefy.loading.open({
        container: this.isFullPage ? null : this.$refs.element.$el
      });
      await this.$store
        .dispatch("deleteInterviewSchedule", { id, index })
        .then(() => {
          this.success("Delete Success");
        })
        .catch(() => {
          this.danger("Cannot delete interview schedule");
        });
      loadingComponent.close();
    },
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: "is-success"
      });
    },
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: "is-danger"
      });
    },
    onPageChange(page) {
      this.currentPage = page;
      this.page = page;
      this.fetchData(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search
      );
    },
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.fetchData(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search
      );
    }
  },
  computed: {
    ...mapGetters(["getInterviewSchedules"])
  }
};
</script>
<style>
.button-action {
  border: none !important;
  color: #00a3a3;
}
</style>
